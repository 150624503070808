<template>
  <page-container>
    <div class="ant-pro-table">
      <div class="ant-pro-table-search">
        <search-render
          ref="config_render"
          :form="config"
          :model="config.model"
          :options="config_options"
          :validateInfos="validateInfos"
          @search="search"
        />
      </div>
      <a-card :body-style="{ padding: 0 }" ref="elRef">
        <div class="ant-pro-table-list-toolbar">
          <div class="ant-pro-table-list-toolbar-container">
            <div class="ant-pro-table-list-toolbar-left">
              <div class="ant-pro-table-list-toolbar-title">未绑定菜单的接口</div>
            </div>
            <div class="ant-pro-table-list-toolbar-right">
              <a-space align="center">
                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip title="表格斑马纹">
                    <a-switch
                      checked-children="开"
                      un-checked-children="关"
                      v-model:checked="state.stripe"
                    />
                  </a-tooltip>
                </div>
              </a-space>
              <div class="ant-pro-table-list-toolbar-divider">
                <a-divider type="vertical" />
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip title="刷新">
                  <reload-outlined @click="handleTableChange" />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip title="密度">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <column-height-outlined />
                    <template #overlay>
                      <a-menu
                        style="width: 80px"
                        :selected-keys="[state.tableSize]"
                        @click="
                          ({ key }) => {
                            state.tableSize = key;
                          }
                        "
                      >
                        <a-menu-item key="default">
                          <a href="javascript:;">默认</a>
                        </a-menu-item>
                        <a-menu-item key="middle">
                          <a href="javascript:;">中等</a>
                        </a-menu-item>
                        <a-menu-item key="small">
                          <a href="javascript:;">紧凑</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-popover
                  placement="bottomRight"
                  arrowPointAtCenter
                  trigger="click"
                  overlayClassName="ant-pro-table-column-setting-overlay"
                >
                  <template #title>
                    <div class="ant-pro-table-column-setting-title">
                      <a-checkbox
                        v-model:checked="columnState.checkAll"
                        :indeterminate="columnState.indeterminate"
                        @change="handleColumnAllClick"
                      >
                        列展示
                      </a-checkbox>
                      <a @click="reset">重置</a>
                    </div>
                  </template>
                  <template #content>
                    <span class="ant-pro-table-column-setting-list">
                      <drag-container
                        lockAxis="y"
                        dragClass="ant-pro-table-drag-ghost"
                        dropClass="ant-pro-table-drop-ghost"
                        @drop="({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)"
                      >
                        <draggable :key="column.key" v-for="column in dynamicColumnItems">
                          <div class="ant-pro-table-column-setting-list-item">
                            <drag-icon />
                            <a-checkbox
                              :checked="column.checked"
                              @change="handleColumnChange($event, column)"
                            >
                              {{ column.label }}
                            </a-checkbox>
                          </div>
                        </draggable>
                      </drag-container>
                    </span>
                  </template>
                  <a-tooltip title="列设置">
                    <setting-outlined />
                  </a-tooltip>
                </a-popover>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="screenState ? '退出全屏' : '全屏'">
                  <fullscreen-outlined v-if="!screenState" @click="setFull" />
                  <fullscreen-exit-outlined v-else @click="exitFull" />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <a-affix :offset-top="top">
          <a-table
            bordered
            :scroll="{ y: tableHeight.y }"
            :row-class-name="stripe"
            :size="state.tableSize"
            :loading="state.loading"
            :columns="dynamicColumns"
            :data-source="state.dataSource"
            :pagination="{
              current: state.current,
              pageSize: state.pageSize,
              total: state.total,
            }"
            @change="handleTableChange"
          >
            <template #index="{ index }">
              <span>{{ index + 1 + state.pageSize * (state.current - 1) }}</span>
            </template>
            <template #auth="{ text }">
              <a-tag color="#87d068" style="margin-bottom: 1px" v-if="text">是</a-tag>
              <a-tag color="#87d068" style="margin-bottom: 1px" v-if="!text">否</a-tag>
            </template>
            <template #status="{ text }">
              <a-tag :color="statusMap[text].status">
                {{ statusMap[text].text }}
              </a-tag>
            </template>
          </a-table>
        </a-affix>
      </a-card>
    </div>
  </page-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import { Badge } from 'ant-design-vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { queryUrlNotBind } from '@/api/permission/apiurl-manage.ts';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import { useStore } from 'vuex';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import { useForm } from 'ant-design-vue/es/form';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const statusMap = {
  true: { text: '正常', status: 'green' },
  false: { text: '作废', status: 'red' },
};
const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    width: 100,
    dataIndex: 'index',
    slots: { customRender: 'index' },
  },
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: 'url',
    dataIndex: 'url',
  },
  {
    title: '是否需要授权',
    width: 130,
    dataIndex: 'is_auth',
    slots: { customRender: 'auth' },
  },
  {
    title: '状态',
    width: 130,
    dataIndex: 'is_active',
    slots: { customRender: 'status' },
    filters: [
      {
        text: status[0],
        value: true,
      },
      {
        text: status[1],
        value: false,
      },
    ],
  },
];

export default defineComponent({
  name: 'ApiurlManage-2',
  setup() {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const tableHeight = reactive({ y: window.innerHeight - 130 });
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 130;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, true);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();

    const modelRef = reactive({
      org_id: current_org.id,
      is_active: true,
      name: '',
      url: '',
    });
    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryUrlNotBind, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
        ...modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...modelRef,
      });
      reload();
    };

    const config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '角色名称',
          label_i18n: '角色名称',
          placeholder_i18n: '请填写角色名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写角色名称',
        },
        {
          type: 'select',
          name: 'is_auth',
          label: '是否需要授权',
          label_i18n: '是否需要授权',
          placeholder_i18n: '请选择是否授权',
          disabled: false,
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择是否授权',
          datasource: 'is_auth',
          mode: 'default',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
          mode: 'default',
        },
      ],
      rules: {},
      model: modelRef,
    };
    const { validateInfos } = useForm(modelRef, {});
    const config_options = {
      is_active: [
        { name: '全部', value: '' },
        { name: '正常', value: true },
        { name: '作废', value: false },
      ],
      is_auth: [
        { name: '全部', value: '' },
        { name: t('是'), value: true },
        { name: t('否'), value: false },
      ],
    };

    return {
      statusMap,
      tableHeight,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      modelRef,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,

      config,
      config_options,
      validateInfos,
    };
  },
  components: {
    DragIcon,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    SearchRender,
    [Badge.name]: Badge,
  },
});
</script>
